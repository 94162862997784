import { roundLeft, roundRight } from "images"
import React, { useState } from "react"
import { getThumb } from "utils"
import * as styles from "./openCardList.module.css"

const NewOpenCardList = props => {
  const [isView, setIsView] = useState(false)
  const [pageNum, setPageNum] = useState(0)

  return (
    <>
      <div>
        {isView ? (
          <div>
            <div
              className={styles.float}
              onClick={() => setIsView(state => !state)}
            >
              <div className={styles.wrap}>
                <img
                  className={styles.prevCard}
                  onClick={e => e.stopPropagation()}
                  src={
                    props.list[
                      pageNum === 0 ? props.list.length - 1 : pageNum - 1
                    ]
                  }
                />

                <img
                  className={styles.preBtn}
                  onClick={e => {
                    e.stopPropagation()
                    if (pageNum === 0) {
                      setPageNum(props.list.length - 1)
                    } else {
                      setPageNum(state => state - 1)
                    }
                  }}
                  src={roundLeft}
                />

                <img
                  className={styles.nowCard}
                  src={props.list[pageNum]}
                  onClick={e => e.stopPropagation()}
                />

                <img
                  className={styles.nextBtn}
                  onClick={e => {
                    e.stopPropagation()
                    if (pageNum === props.list.length - 1) {
                      setPageNum(0)
                    } else {
                      setPageNum(state => state + 1)
                    }
                  }}
                  src={roundRight}
                />

                <img
                  className={styles.nextCard}
                  src={
                    props.list[
                      pageNum === props.list.length - 1 ? 0 : pageNum + 1
                    ]
                  }
                  onClick={e => e.stopPropagation()}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.cardDetail}>
        {props?.list?.map((item, i) => {
          return (
            <div key={i}>
              <img
                src={item ? item : ""}
                onClick={() => {
                  setIsView(state => !state)
                  setPageNum(i)
                }}
                id={item}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default NewOpenCardList
