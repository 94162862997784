import React, { useEffect, useState, useContext, useRef } from "react"
import * as styles from "./detail.module.css"
import Layout from "components/common/layout.js"
import queryString from "query-string"
import Cardinfo from "components/story/cardInfo"
import DetailHeader from "components/post/detailHeader"
import fullView from "images/smallSliceIcon.png"
import api from "apis"
import { getDateYMD, textEdit, getThumb, getRawThumb } from "utils"
import InputTitle from "components/tools/InputTitle.js"
import Slider from "components/tools/slide/Slide.js"
import Story from "components/story/story"
import { smalloffFavor, smallonFavor } from "images"
import { storyCategory, table, topics } from "utils/data.js"
import Lesson from "components/post/lesson.js"
// import CardSlider from 'components/tools/slide/cardSlider'
import { navigate } from "gatsby"
// import * as peach from "../../../static/bundle.js"
import { permission } from "utils"
import Like from "components/tools/Like"
import NewOpenCardList from "components/tools/openCardList/openCardList"
const DetailPage = props => {
  const { number, currentPage, pagePostSize } = queryString.parse(
    props.location.search
  )
  const { main, sub } = storyCategory

  const [slidePage, setSlidePage] = useState(Number(currentPage))
  const [post, setPost] = useState({})
  const [associatedStory, setAssociatedStory] = useState([])
  const [eduNanumStory, setEduNanumStory] = useState([])
  const [cardOpen, setCardOpen] = useState(false)
  const [peach, setPeach] = useState({})

  useEffect(() => {
    ;(async () => {
      if (!number) number = 1
      setPeach(await import("../../../static/bundle.js"))

      console.log(number)

      const post = await api.detailedClassinfo(number)
      console.log(post)
      console.log(table.get(4))
      const associatedStory = await api.associatedStory(number, table.get(4))
      console.log(associatedStory)
      // if (!post || !associatedStory) navigate("/story/category/list")

      setAssociatedStory(associatedStory)

      const eduNanumStory = await api.associatedStory(number, "story_nanum")
      console.log(eduNanumStory)
      //  if (!post || !eduNanumStory) navigate("/story/category/list")

      setEduNanumStory(eduNanumStory)
      console.log(post)
      if (post)
        setPost({
          thumb_title_uuid: post.thumb_title_uuid,
          title: post.title,
          contents: textEdit(post.bodyText),
          intro: textEdit(post.introText),
          time: getDateYMD(post.registerTime, "YYYY.MM.DD"),
          Like: post.Like,
          like: post.like,
          cateMain: post.cateMain,
          cateSub: post.cateSub,
          hit: post.hit || 0,
          publishTime: post.publishTime,
          suggestText: textEdit(post.suggestText),
          story_pages: post.pages,
          cards: JSON.parse(post.StoryRaw.cards),
          Topics: post.topics
            .toString(2)
            .split("")
            .reverse()
            .map((elem, idx) => {
              if (parseInt(elem) === 1) return ++idx
            })
            .filter(elem => elem !== undefined),
          free: post?.free,
        })
    })()
  }, [props])

  const readStory = () => {
    const url = `/peachPage?story_no=${number}`
    post?.free ? navigate(url) : permission(url)
  }

  const cardList = post?.cards?.map(v => getRawThumb(v))

  const openCardList = index => {
    const { view } = peach.peachSlide(cardList, index)
    view.style.position = "fixed"
    view.style.left = "0px"
    view.style.right = "0px"
    view.style.top = "0px"
    view.style.bottom = "0px"
    view.style.width = "100vw"

    document.body.appendChild(view)
  }

  return (
    <Layout>
      <Cardinfo />
      <DetailHeader
        left={
          <img
            src={post?.thumb_title_uuid ? getThumb(post?.thumb_title_uuid) : ""}
            alt={""}
            style={{
              width: "350px",
              height: "350px",
              minWidth: "350px",
            }}
          />
        }
        right={
          <div style={{ textAlignLast: "left", minWidth: "450px" }}>
            <div className={styles.firstCon}>
              <div>{main.get(post?.cateMain)}</div>

              <div>{sub.get(post?.cateSub)}</div>
            </div>
            <div
              style={{
                fontSize: "26px",
                paddingTop: "26px",
                fontFamily: "RIDIBatang",
                whiteSpace: "nowrap",
                width: "500px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {post?.title}
            </div>

            <div className={styles.tag}>
              {post?.Topics?.map(post => `#${topics.get(post).contents}  `)}
            </div>

            <div
              style={{
                fontSize: "14px",
                paddingTop: "26px",
                textAlignLast: "right",
                color: "#7C7C7C",
                fontWeight: "400",
              }}
            >
              {post.story_pages} 쪽 |{" "}
              {getDateYMD(post.publishTime, "YYYY년 MM월 DD일")} 발행
            </div>

            <div
              style={{
                textAlignLast: "center",
                display: "flex",
                marginTop: "54px",
              }}
            >
              <div
                className={"confirmBtn"}
                style={{ width: "155px", marginRight: "10px" }}
                onClick={readStory}
              >
                스토리 읽기
              </div>
              <div
                className={"cancelBtn"}
                style={{ width: "155px", lineHeight: "43px" }}
                onClick={() =>
                  cardOpen ? setCardOpen(false) : setCardOpen(true)
                }
              >
                그림단어
              </div>
              <div>
                {/* <img
                  style={{ width: "47px", marginLeft: "15px" }}
                  src={post.Like ? smallonFavor : smalloffFavor}
                  onClick={e => {
                    onLike(e, number, table.get(4), true)
                  }}
                /> */}
                <Like
                  on={smallonFavor}
                  off={smalloffFavor}
                  keyword={"story"}
                  isLike={post?.Like}
                  style={{ img: { width: "47px", marginLeft: "15px" } }}
                  postNumber={number}
                />
              </div>
            </div>
          </div>
        }
      >
        <>
          {cardOpen ? (
            <div className={styles.cardCon}>
              <div className={styles.card}>
                <div>
                  <span style={{ color: "#E95C63" }}>'{post?.title}'</span>{" "}
                  스토리로 배우는 그림단어
                </div>

                <div>
                  <div>
                    <div style={{ backgroundColor: "#E58A83" }} />
                    <div>명사</div>
                  </div>

                  <div>
                    <div style={{ backgroundColor: "#75BAA7" }} />

                    <div>동사</div>
                  </div>

                  <div>
                    <div style={{ backgroundColor: "#75C4FC" }} />

                    <div>형용사</div>
                  </div>

                  <div>
                    <div style={{ backgroundColor: "#FEE469" }} />
                    <div>부사</div>
                  </div>

                  <div>
                    <div className={styles.tagSame}>( )</div>

                    <div>동음이의어</div>
                  </div>
                </div>
              </div>
              <NewOpenCardList list={cardList} />
              {/* <div className={styles.cardDetail}>
                {post?.cards?.map((item, i) => {
                  return (
                    <div key={i}>
                      <img
                        src={item ? getRawThumb(item) : ""}
                        onClick={() => openCardList(i + 1)}
                        id={item}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )
                })}
              </div> */}
              <hr
                style={{ width: "100vw", position: "absolute", left: "0px" }}
              />
            </div>
          ) : null}
        </>

        <div>{/* <CardSlider card={post?.cards}></CardSlider> */}</div>
        <div className={styles.con}>
          <div>
            <div>스토리 소개</div>
            <div>{post?.intro}</div>
          </div>

          <div>
            <div>이런 선생님께 추천합니다</div>
            <div>{post?.suggestText}</div>
          </div>
        </div>
      </DetailHeader>

      <div
        style={{
          margin: "0px auto",
          width: "100%",
          maxWidth: "850px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 155,
            marginBottom: 30,
          }}
        >
          <InputTitle
            name={"이 스토리를 활용한 수업나눔"}
            style={{ width: "620px" }}
            titleSize={22}
          />

          <div
            style={{
              display: "flex",
              fontSize: "16px",
              placeItems: "center",
              marginLeft: "120px",
              minWidth: "fit-content",
              cursor: "pointer",
            }}
            onClick={() => navigate("/class/lesson/list")}
          >
            <div>전체 보기</div>
            <img
              src={fullView}
              style={{ width: "17px", height: "17px", marginLeft: "5px" }}
            />
          </div>
        </div>

        <div className={styles.slideCon}>
          {eduNanumStory?.length ? (
            <Slider name={"associated"}>
              {eduNanumStory?.map((data, i) => {
                return (
                  <Lesson
                    post={{
                      ...data,
                      Story: { thumb_title_uuid: post?.thumb_title_uuid },
                    }}
                    key={i}
                  />
                )
              })}
            </Slider>
          ) : (
            <div
              style={{
                color: "#7C7C7C",
                fontSize: "18px",
                margin: "130px 0",
                fontWeight: "500",
              }}
            >
              “등록된 수업나눔이 없습니다. 스토리를 활용한 수업안을 가장 먼저
              작성해 보세요.”
            </div>
          )}

          <div
            className={"confirmBtn"}
            style={{
              width: "155px",
              marginLeft: "auto",
              marginRight: "10px",
              fontWeight: "400",
              fontWeight: "normal",
              letterSpacing: "-0.4px",
            }}
            onClick={() => permission("/class/lesson/write/")}
          >
            수업나눔 작성하기
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: 30,
            marginTop: 155,
          }}
        >
          <InputTitle
            name={"함께 보면 좋은 스토리"}
            titleSize={22}
            style={{ width: "730px" }}
          />

          <div
            style={{
              display: "flex",
              fontSize: "16px",
              placeItems: "center",
              minWidth: "fit-content",
              cursor: "pointer",
            }}
            onClick={() => navigate("/story/interest/list")}
          ></div>
        </div>

        <div className={styles.slideCon}>
          <Slider name={"associated"}>
            {associatedStory?.map(post => (
              <Story
                registerTime={post.publishTime}
                key={post.story_no}
                story_no={post.story_no}
                title={post.title}
                introText={post.introText}
                thumb_title_uuid={post.thumb_title_uuid}
                cateMain={post.cateMain}
                cateSub={post.cateSub}
                Like={post.Like}
                free={post.free}
              />
            ))}
          </Slider>
        </div>
      </div>
    </Layout>
  )
}

export default DetailPage
